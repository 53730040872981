<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";

export default {
  props: ["id", "customClass"],
  data() {
    return {
      
    };
  },
  methods: {
    async playOut() {
      
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    BarChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 80, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xRange = [marginLeft, width - marginRight], // [left, right]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        duration = 1000, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
        yPadding = 0.1, // 柱子的比例
      } = {}
    ) {
        let xDomain = data.map(d => d.value)
        let yDomain = data.map(d => d.name)
        
        // 创建svg元素
        const svg = d3
          .create("svg")
          .attr("width", width)
          .attr("height", height)
          .attr("viewBox", [0, 0, width, height])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
        // y轴linear比例尺
        // const yScale = d3.scaleLinear([yMin, yMax], yRange);
        const yScale = d3.scaleBand(yDomain, yRange).padding(yPadding)
        const xScale = d3.scaleLinear([0, d3.max(xDomain) + 5], xRange)
        // 定义axis
        const yAxis = d3.axisLeft(yScale).ticks(8).tickSizeOuter(0);
        const xAxis = d3.axisBottom(xScale).ticks(10).tickSizeOuter(0);
        //y轴坐标轴
        const axisY = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("opacity", 0)
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis)
          .call((g) => {
            // g.select(".domain").attr("class", "domain").attr("opacity", 1);
          });
        // x轴坐标
        const axisX = svg
          .append("g")
          .attr("class", "axis_x")
          .attr("opacity", 0)
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(xAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
            g.selectAll(".tick line")
            .clone()
            .attr("y2", marginTop + marginBottom - height)
            .attr("stroke", "rgba(0,0,0,.1)")
            .attr("class", "tick_long_line");
          });
        axisX.selectAll(".tick_long_line").transition().duration(400).attr("opacity", 1);
         axisX.transition().duration(duration).attr("opacity", 1);
        //柱子
        const bars = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("x", (i) => xScale(0))
          .attr("y", (d, i) => {
            return yScale(d.name)
          })
          .attr("fill", '#FFC400')
          .attr("height", yScale.bandwidth())
          .attr("width", 0)
        bars.transition()
          // .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("width", (d) => xScale(d.value) - marginLeft)
        // 插入svg元素
        $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
