<template>
  <div id="chipDistChart">
    <div class="pages hidden" id="chipDistPages">
      <div class="page_title"><span class="text hidden" id="chipDistText"></span></div>
      <div class="page_date">{{ updateDate }}</div>
      <div ref="refChipDistChartHolder" v-if="isChipDistChartVisible">
        <ul class="chipDistlegend" v-if="data.type === '个股筹码分布'">
          <li>压力位</li>
          <li>平均成本</li>
          <li>支持位</li>
        </ul>
        <ul class="chipDistlegendMain" v-else>
          <li>主力成本</li>
        </ul>
        <chip-bar-chart ref="refChipDistChart" id="chipDist" customClass="chip-dist-chart" />
      </div>
      <Parse v-if="isChipDistParseVisible" :data="data" />
      <div ref="refQuoteTableHolder" v-if="isQuoteTableVisible">
        <table-chart ref="refQuoteTable" id="quoteTable" customClass="quote_table_chart" />
      </div>
      <div ref="refFormHolder" v-if="isFormVisible">
        <h3 class="total">A股共计：{{ data.data.data }}只</h3>
        <form-chart ref="refForm" id="refForm" customClass="chip-dist-chart" />
      </div>
      <div ref="refFoundHolder" v-if="isFoundVisible">
         <bar-chart ref="refFound" id="refFound" customClass="bar_chart" />
         <ul class="foundList">
           <li v-for="(item, index) in data.data.desc" :key="index">{{ item }}</li>
         </ul>
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是marketChart
import $ from "jquery";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import chipBarChart from './components/BarChart.vue'
import tableChart from "@/components/tableChart/index.vue";
import formChart from './components/BarImgChart.vue';
import Parse from './components/Parse.vue'
import barChart from "@/components/barChart/index_v1.0.vue";
export default {
  data: function () {
    return {
      titleText: null,  // 动画句柄
      updateDate: "",  // 数据更新日期
      isChipDistChartVisible: false,
      isChipDistParseVisible: false,
      isQuoteTableVisible: false,
      isFormVisible: false,
      isFoundVisible: false,
      data: {},
    };
  },
  components: {
    chipBarChart,
    Parse,
    tableChart,
    formChart,
    barChart
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        if (this.titleText) {
          this.titleText.runOut();
        }
        await sleep(200);
        $("#chipDistText").addClass("hidden");
        this.isChipDistChartVisible = false
        this.isChipDistParseVisible = false
        this.isQuoteTableVisible = false
        this.isFormVisible = false
        this.isFoundVisible = false
        this.updateDate = "";
        this.data = {}
        resolve();
      });
    },
    async show(commandData) {
      //设置标题
      const title = commandData.data.pageTitle;
      this.isChipDistChartVisible = false
      this.isChipDistParseVisible = false
      this.isQuoteTableVisible = false
      this.isFormVisible = false
      this.isFoundVisible = false
      this.updateDate = "";
      this.data = {}
      $("#chipDistPages").removeClass("hidden");
      $("#chipDistText").html(title);
      this.$nextTick(async () => {
        $("#chipDistText").removeClass("hidden");
        this.titleText = anyElement({
          targets: document.querySelector("#chipDistText"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "rightInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        if (["个股筹码分布", "个股主力筹码分布"].indexOf(commandData.type) !== -1 && commandData.subType === '趋势') {
          this.handleChipDist(commandData)
        }
        if (["个股筹码分布", "个股主力筹码分布"].indexOf(commandData.type) !== -1 && commandData.subType === '解析') {
          this.handleChipDistParse(commandData)
        }
        if (["筹码形态分析"].indexOf(commandData.type) !== -1 && commandData.subType.indexOf('形态') === -1) {
          this.handleQuote(commandData)
        }
        if (["筹码形态分析"].indexOf(commandData.type) !== -1 && commandData.subType.indexOf('形态') !== -1) {
          this.handleForm(commandData)
        }
        if (commandData.type === '全市场盈利比例分布' && commandData.subType === 'all') {
          this.handleFound(commandData)
        }
        if (commandData.type === '全市场盈利比例分布' && commandData.subType !== 'all') {
         this.handleQuote(commandData)
        }
      });
    },
    handleChipDist(commandData) {
      this.isChipDistChartVisible = true
      this.data = commandData
      this.updateDate = commandData.data.chipDist ? commandData.data.chipDist.sUpdateDate : commandData.data.chipDistMain.sUpdateDate
      this.$nextTick(() => {
        this.renderChipDist(commandData)
      });
    },
    renderChipDist(commandData) {
      let width = this.$refs.refChipDistChartHolder.clientWidth
      this.$refs.refChipDistChart.BarChart(commandData.type === "个股筹码分布" ? commandData.data.chipDist.dataConverted : commandData.data.chipDistMain.dataConvertedMain, {
        width: width,
        height: width * 0.75,
        marginTop: 20,
        marginLeft: 40,
        marginRight: 50,
      })
    },
    handleChipDistParse(commandData) {
      this.isChipDistParseVisible = true
      this.data = commandData
    },
    handleQuote(commandData) {
      this.isQuoteTableVisible = true;
      this.$nextTick(() => {
        this.renderTableChart(commandData);
      });
    },
    renderTableChart(commandData) {
      let width = this.$refs.refQuoteTableHolder.clientWidth;
      this.$refs.refQuoteTable.TableChart(commandData.data.data, {
        width: width,
        height: width * 0.77,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
      this.updateDate = commandData.data.date;
    },
    handleForm(commandData) {
      this.isFormVisible = true
      this.data = commandData
      this.$nextTick(() => {
        this.renderForm(commandData);
      });
    },
    renderForm(commandData) {
      let data = []
      if (commandData.subType === "底部单峰密集型筹码形态") {
        data = [
          { name: 'y1', value: 1},
          { name: 'y2', value: 1},
          { name: 'y3', value: 1},
          { name: 'y4', value: 1.5},
          { name: 'y5', value: 2.5},
          { name: 'y6', value: 4},
          { name: 'y7', value: 2.5},
          { name: 'y8', value: 3},
          { name: 'y9', value: 3.5},
          { name: 'y10', value: 2},
          { name: 'y11', value: 5},
          { name: 'y12', value: 4.5},
          { name: 'y13', value: 4},
          { name: 'y14', value: 5},
          { name: 'y15', value: 6},
          { name: 'y16', value: 7},
          { name: 'y17', value: 9},
          { name: 'y18', value: 8},
          { name: 'y19', value: 7},
          { name: 'y20', value: 9},
          { name: 'y21', value: 8},
          { name: 'y22', value: 10},
          { name: 'y23', value: 9},
          { name: 'y24', value: 8},
          { name: 'y25', value: 5},
          { name: 'y26', value: 4},
          { name: 'y27', value: 2.5},
          { name: 'y28', value: 1.5},
          { name: 'y29', value: 1},
          { name: 'y30', value: 1},
        ]
      }
      if (commandData.subType === "中部单峰密集型筹码形态") {
        data = [
          { name: 'y1', value: 1},
          { name: 'y2', value: 1},
          { name: 'y3', value: 1},
          { name: 'y4', value: 1.5},
          { name: 'y5', value: 2},
          { name: 'y6', value: 2.5},
          { name: 'y7', value: 3},
          { name: 'y8', value: 3.5},
          { name: 'y9', value: 4.5},
          { name: 'y10', value: 4},
          { name: 'y11', value: 6},
          { name: 'y12', value: 7},
          { name: 'y13', value: 8},
          { name: 'y14', value: 8.5},
          { name: 'y15', value: 9},
          { name: 'y16', value: 10},
          { name: 'y17', value: 9},
          { name: 'y18', value: 8},
          { name: 'y19', value: 7},
          { name: 'y20', value: 7},
          { name: 'y21', value: 6},
          { name: 'y22', value: 5},
          { name: 'y23', value: 4},
          { name: 'y24', value: 3},
          { name: 'y25', value: 2},
          { name: 'y26', value: 2.5},
          { name: 'y27', value: 2},
          { name: 'y28', value: 1.5},
          { name: 'y29', value: 1},
          { name: 'y30', value: 1},
        ]
      }
      if (commandData.subType === "顶部单峰密集型筹码形态") {
        data = [
          { name: 'y1', value: 1},
          { name: 'y2', value: 2},
          { name: 'y3', value: 1},
          { name: 'y4', value: 5},
          { name: 'y5', value: 7},
          { name: 'y6', value: 8},
          { name: 'y7', value: 9},
          { name: 'y8', value: 10},
          { name: 'y9', value: 9},
          { name: 'y10', value: 8},
          { name: 'y11', value: 7},
          { name: 'y12', value: 8},
          { name: 'y13', value: 6},
          { name: 'y14', value: 5},
          { name: 'y15', value: 3},
          { name: 'y16', value: 3.5},
          { name: 'y17', value: 3},
          { name: 'y18', value: 2},
          { name: 'y19', value: 2.5},
          { name: 'y20', value: 2},
          { name: 'y21', value: 1.8},
          { name: 'y22', value: 1.7},
          { name: 'y23', value: 1.5},
          { name: 'y24', value: 1.7},
          { name: 'y25', value: 1.4},
          { name: 'y26', value: 1.3},
          { name: 'y27', value: 0.8},
          { name: 'y28', value: 1},
          { name: 'y29', value: 1.2},
          { name: 'y30', value: 0.5},
        ]
      }
      if (commandData.subType === "双峰密集型筹码形态") {
        data = [
          { name: 'y1', value: 1},
          { name: 'y2', value: 1.2},
          { name: 'y3', value: 1},
          { name: 'y4', value: 0.5},
          { name: 'y5', value: 5},
          { name: 'y6', value: 7},
          { name: 'y7', value: 9},
          { name: 'y8', value: 8},
          { name: 'y9', value: 10},
          { name: 'y10', value: 5},
          { name: 'y11', value: 3},
          { name: 'y12', value: 2},
          { name: 'y13', value: 1.5},
          { name: 'y14', value: 2.5},
          { name: 'y15', value: 3},
          { name: 'y16', value: 5},
          { name: 'y17', value: 7},
          { name: 'y18', value: 8},
          { name: 'y19', value: 8.5},
          { name: 'y20', value: 10},
          { name: 'y21', value: 9},
          { name: 'y22', value: 7},
          { name: 'y23', value: 6},
          { name: 'y24', value: 5},
          { name: 'y25', value: 4},
          { name: 'y26', value: 3},
          { name: 'y27', value: 4},
          { name: 'y28', value: 2},
          { name: 'y29', value: 1},
          { name: 'y30', value: 1},
        ]
      }
      if (commandData.subType === "发散型筹码形态") {
        data = [
          { name: 'y1', value: 5},
          { name: 'y2', value: 4},
          { name: 'y3', value: 6},
          { name: 'y4', value: 3.5},
          { name: 'y5', value: 5},
          { name: 'y6', value: 4.5},
          { name: 'y7', value: 7},
          { name: 'y8', value: 5},
          { name: 'y9', value: 5.5},
          { name: 'y10', value: 5},
          { name: 'y11', value: 7},
          { name: 'y12', value: 6.5},
          { name: 'y13', value: 6},
          { name: 'y14', value: 5.5},
          { name: 'y15', value: 6.5},
          { name: 'y16', value: 7.5},
          { name: 'y17', value: 6},
          { name: 'y18', value: 6.5},
          { name: 'y19', value: 7},
          { name: 'y20', value: 6},
          { name: 'y21', value: 4},
          { name: 'y22', value: 5.5},
          { name: 'y23', value: 6.5},
          { name: 'y24', value: 7},
          { name: 'y25', value: 6.5},
          { name: 'y26', value: 8},
          { name: 'y27', value: 7},
          { name: 'y28', value: 4},
          { name: 'y29', value: 1},
          { name: 'y30', value: 1},
        ]
      }
      let width = this.$refs.refFormHolder.clientWidth;
      this.$refs.refForm.BarChart(data.reverse(), {
        width: width,
        height: width * 0.76,
        marginTop: 20,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 20,
      })
    },
    handleFound(commandData) {
      this.isFoundVisible = true
      this.data = commandData
      this.$nextTick(() => {
        this.renderFound(commandData);
      });
    },
    renderFound(commandData) {
      let width = this.$refs.refFoundHolder.clientWidth;
       this.$refs.refFound.BarChart(commandData.data.data, {
        x: (d) => d.name,
        y: (d) => d.value,
        width: width,
        height: width * 0.56,
        marginLeft: 50,
        marginRight: 25,
        marginTop: 40,
        marginBottom: 40,
        xPadding: 0.3,
        duration: 800,
        delay: 80,
        ease: "easeCubic",
        isFromZero: true,
        isShowNumber: true,
        numberSuffix: '家'
      });
    }
  },
  async mounted() {
    window.hide = this.hide
    window.show = this.preview;
    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
