<template>
  <div :id="id" :class="customClass" :style="`width:${width}px;height:${height}px`">
    <ul :class="{ has_zebra: titleRows.length==0 }">
      <li
        v-for="(item, iIndex) in tableData"
        :key="iIndex"
        :class="{
          li_title: iIndex == 0,
          li_body: iIndex > 0,
          li_title: titleRows.indexOf(iIndex) > -1,
          li_highlight: highlightRows.indexOf(iIndex) > -1,
        }"
      >
        <span
          v-for="(cell, cIndex) in item"
          :key="cIndex"
          :class="{ num_positive: cell > 0, num_negative: cell < 0 }"
          v-html="`${cell}${iIndex != 0 && cIndex == 1 && col2Suffix ? col2Suffix : ''}${iIndex != 0 && cIndex == 2 && col3Suffix ? col3Suffix : ''}${iIndex != 0 && cIndex == 3 && col4Suffix ? col4Suffix : ''}${iIndex != 0 && cIndex == 4 && col5Suffix ? col5Suffix : ''}`"
        >
          <!-- {{ cell }}
          {{ iIndex != 0 && cIndex == 1 && col2Suffix ? col2Suffix : ""}}
          {{ iIndex != 0 && cIndex == 2 && col3Suffix ? col3Suffix : ""}}
          {{ iIndex != 0 && cIndex == 3 && col4Suffix ? col4Suffix : ""}}
          {{ iIndex != 0 && cIndex == 4 && col5Suffix ? col5Suffix : "" }} -->
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import $ from "jquery";
import { sleep } from "@/utils/utils.js";
import anime from "animejs/lib/anime.es.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      tableData: [],
      tableAnimationHandle: null,
      width: 300,
      height: 300,
      col2Suffix: "",
      col3Suffix: "",
      col4Suffix: "",
      col5Suffix: "",
      titleRows: [],
      highlightRows: [],
    };
  },
  methods: {
    async playOut() {},
    //核心库
    TableChart(
      data,
      {
        width = 300,
        height = 300,
        duration = 400, //动画持续时长
        delay = 100, //元素之间间隔时长
        ease = "linear",
        col2Suffix = "",
        col3Suffix = "",
        col4Suffix = "",
        col5Suffix = "",
        titleRows = [],
        highlightRows = [],
      } = {}
    ) {
      this.width = width;
      this.height = height;
      this.tableData = data;
      this.col2Suffix = col2Suffix;
      this.col3Suffix = col3Suffix;
      this.col4Suffix = col4Suffix;
      this.col5Suffix = col5Suffix;
      this.titleRows = titleRows;
      this.highlightRows = highlightRows;

      this.$nextTick(() => {
        anime({
          targets: `#${this.id} li`,
          duration: duration,
          delay: anime.stagger(delay),
          easing: ease,
          opacity: [0, 1],
          translateY: [60, 0],
          translateX: [-60, 0],
          translateZ: [100, 0],
          scale: [0.8, 1],
        });
      });
    },
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
// @import "./index.less";
</style>
