<template>
    <div class="parse">
        <template v-if="data.type === '个股筹码分布'">
            <ul>
                <li>
                    <span>平均成本</span>
                    <span style="color: #fdab36">{{ data.data.chipDist.fAvgCost }}</span>
                </li>
                <li>
                    <span>分析周期</span>
                    <span>30天</span>
                </li>
                <li>
                    <span>支撑位</span>
                    <span style="color: #2085df">{{ data.data.chipDist.fSupportPrice }}</span>
                </li>
                <li>
                    <span>压力位</span>
                    <span style="color: #8f19a4">{{ data.data.chipDist.fPressPrice }}</span>
                </li>
                <li>
                    <span>筹码形态</span>
                    <span style="fontWeight: bold">{{ data.data.chipShape }}</span>
                </li>
            </ul>
            <p>{{ data.data.chipDesc }}。{{ data.data.chipDist.sEarnConcenDesc}}</p>
            <div>
                <span>盈利比例</span>
                <el-progress :stroke-width="26" :percentage="Number((data.data.chipDist.fEarningPer * 100).toFixed(2))" color="#FF3B30"></el-progress>
            </div>
        </template>
        <template v-else>
            <ul>
                <li>
                    <span>主力成本</span>
                    <span style="color: #fdab36">{{ data.data.chipDistMain.fMainAvgCost }}</span>
                </li>
                <li>
                    <span>主力获利</span>
                    <span>{{ this.validValueAllowZero(data.data.chipDistMain.fMainIncRate * 100, '%') }}</span>
                </li>
            </ul>
            <p>{{ data.data.mainChipDesc}}</p>
            <div>
                <span>盈利比例</span>
                <el-progress :stroke-width="26" :percentage="(data.data.chipDistMain.fMainEarnPer * 100).toFixed(2)" color="#FF3B30"></el-progress>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: ["data"],
    data: function() {
        return {}
    },
    methods: {
        getShapeTxt(type){
            let shapeArr=['发散型','底部单峰密集型','中部单峰密集型','顶部单峰密集型','双峰密集型'];
            return shapeArr[type-1];
        },
        validValueAllowZero(data, postfix) {
            var _postfix = postfix === undefined ? '' : postfix;
            if (data === 0) {
                return '0' + _postfix;
            } else {
                return data.toFixed(2) + _postfix;
            }
        },
    },
}
</script>
<style lang="less" scoped>
.parse {
    font-size: 34px;
    > ul {
        display: flex;
        flex-wrap: wrap;
        > li {
            width: 36%;
            margin-top: 34px;
            display: flex;
            justify-content: space-between;
            margin-right: 14%;
            &:nth-child(5) {
                width: 100%;
                justify-content: flex-start;
                > span {
                    margin-right: 54px;
                }
            }
        }
    }
    > p {
        margin-top: 80px;
    }
    > div {
        display: flex;
        align-items: center;
        margin-top: 80px;
        > span {
            font-weight: bold;
        }
        /deep/ .el-progress {
            flex: 1;
            margin-left: 34px;
            .el-progress-bar {
                width: 76%;
                padding: 0;
                margin-right: 28px;
            }
            .el-progress__text {
                color: #FF3B30;
                font-weight: bold;
            }
        }
    }
}

</style>