<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";

export default {
  props: ["id", "customClass"],
  data() {
    return {
      
    };
  },
  methods: {
    async playOut() {
      
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    BarChart(
      data,
      {
        marginTop = 40, // the top margin, in pixels
        marginRight = 80, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xRange = [marginLeft, width - marginRight], // [left, right]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        duration = 1000, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeBack", //元素之间间隔时长
      } = {}
    ) {
        let avePrice = data[0].avePrice;
        let closePrice = data[0].closePrice;
        let pressPrice = data[0].pressPrice;
        let supportPrice = data[0].supportPrice;
        let mainAvgCost = data[0].mainAvgCost;

        let yMax = data[0].yMax;
        let yMin = data[0].yMin;
        let xMax = data[0].xMax;
        // 创建svg元素
        const svg = d3
          .create("svg")
          .attr("width", width)
          .attr("height", height)
          .attr("viewBox", [0, 0, width, height])
          .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
        // y轴linear比例尺
        const yScale = d3.scaleLinear([yMin, yMax], yRange);
        const xScale = d3.scaleLinear([0, xMax], xRange)
        // 定义axis
        const yAxis = d3.axisLeft(yScale).ticks(8).tickSizeOuter(0);
        const xAxis = d3.axisBottom(xScale).ticks(10).tickSizeOuter(0);
        //y轴坐标轴
        const axisY = svg
          .append("g")
          .attr("class", "axis_y")
          .attr("opacity", 0)
          .attr("transform", `translate(${marginLeft},0)`)
          .call(yAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("stroke", "rgba(0,0,0,.1)").attr("opacity", 0);
          });
        axisY.selectAll(".tick line").transition().duration(duration).attr("class", "tick_line").attr("opacity", 1);
        axisY.transition().duration(duration).attr("opacity", 1);
        // x轴坐标
        const axisX = svg
          .append("g")
          .attr("class", "axis_x")
          .attr("opacity", 0)
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(xAxis)
          .call((g) => {
            g.select(".domain").attr("class", "domain").attr("opacity", 0);
            g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
            g.selectAll(".tick line")
            .clone()
            .attr("y2", marginTop + marginBottom - height)
            .attr("stroke", "rgba(0,0,0,.1)")
            .attr("class", "tick_long_line");
            g.selectAll(".tick text").attr("class", "text").text((d) => {
              return `${d}%`
            });
          });
        axisX.selectAll(".tick_long_line").transition().duration(400).attr("opacity", 1);
        axisX.transition().duration(duration).attr("opacity", 1);
        //柱子
        const bars = svg
          .append("g")
          .attr("class", "bar_group")
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("x", (i) => xScale(0))
          .attr("y", (d, i) => {
            return yScale(d.name)
          })
          .attr("class", (d, i) => {
                if (d.name < closePrice*1) {
                    return "bar_positive";
                } else if(d.name == closePrice*1){
                    return "bar_plate";
                }else if (d.name > closePrice) {
                    return "bar_negative";
                }
          })
          .attr("height", 1)
          .attr("width", 0)
        bars.transition()
          // .delay((d, i) => i * delay)
          .duration(duration)
          .ease(d3[ease + "Out"])
          .attr("width", (d) => xScale(d.value) - marginLeft)
        //压力位线和数字
        if (pressPrice != undefined && pressPrice != 0) {
            var pressLine = svg.append("g").attr('class', 'g_press')
            pressLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(pressPrice / 1))
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(pressPrice / 1))
                .attr("class", "press_line")
            pressLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(pressPrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textPress")
                .attr("dy", "4px")
                .text((pressPrice / 1).toFixed(2))
        }
        //平均值线和数字
        if (avePrice != undefined && avePrice != 0) {
            var aveLine = svg.append("g").attr('class', 'g_ave')
            aveLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(avePrice / 1) + 0.8)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(avePrice / 1) + 0.8)
                .attr("class", "ave_line")
            aveLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(avePrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textAve")
                .attr("dy", "16px")
                .text((avePrice / 1).toFixed(2))
        }

        //支撑位线和数字
        if (supportPrice != undefined && supportPrice != 0) {
            var supportLine = svg.append("g").attr('class', 'g_support')
            supportLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(supportPrice / 1) + 0.5)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(supportPrice / 1) + 0.5)
                .attr("class", "support_line")
            supportLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(supportPrice / 1))
                .attr("text-anchor", "start")
                .attr("id", "textSupport")
                .attr("dy", "5px")
                .text((supportPrice / 1).toFixed(2))
        }
         //主力成本线和数字
        if (mainAvgCost != undefined && mainAvgCost != 0) {
            var mainAvgLine = svg.append("g").attr('class', 'g_ave')
            mainAvgLine.append("line")
                .attr("x1", xScale(0))
                .attr("y1", yScale(mainAvgCost / 1) + 0.8)
                .attr("x2", xScale(xMax))
                .attr("y2", yScale(mainAvgCost / 1) + 0.8)
                .attr("class", "ave_line")
            mainAvgLine.append("text")
                .attr('x', xScale(xMax) + 5)
                .attr('y', yScale(mainAvgCost / 1))
                .attr("text-anchor", "start")
                .attr("id", "textAve")
                .attr("dy", "4px")
                .text((mainAvgCost / 1).toFixed(2))
        }
        // 插入svg元素
        $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
